<template>
    <div class="manage_project wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align">
            <div class="formInline-left">
                <el-form-item label="" class="search">
                    <el-input @input="filterCourse()" style="width:316px;" v-model="formInline.search"
                        suffix-icon="el-icon-search" placeholder="请输入问卷名称" size="mini"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="mid-bot">
            <el-table :data="tableData">
                <el-table-column prop="title" label="问卷名称" width="160px" align="center"></el-table-column>
                <el-table-column label="时间" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.beginTime && scope.row.endTime">{{ scope.row.beginTime.split(" ")[0] }} - {{
                            scope.row.endTime.split(" ")[0] }} </span>
                        <span v-else class="noTime"> -- </span>
                        <i class="signIn-status" :class="{ 'signingIn': scope.row.timeStatus == '1' }">{{
                            signStatusMap[scope.row.timeStatus] }}</i>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="操作" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" :disabled="scope.row.timeStatus != 1" @click="handleWjx(1, scope.row.id)">查看</el-button>
                        <el-button type="text" size="small" :disabled="scope.row.timeStatus != 1" @click="handleWjx(0, scope.row.id)">去完成</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="total > 0" :total="total" :page.sync="page" :limit.sync="limit" :pageSizes="[5, 10, 15]"
                @pagination="handlePageChange">
            </pagination>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            statusList: {
                0: '未发布',
                1: '已发布',
                2: '已暂停',
                3: '已删除',
                4: '彻底删除',
                5: '被审核'
            },
            formInline: { //搜索栏表单数据
                search: "", //模糊查询
            },
            tableData: [],
            page: 1,
            limit: 10,
            total: 0,
            signStatusMap: {
                0: '未开始',
                1: '进行中',
                2: '已结束',
            },
        };
    },
    methods: {
        // 翻页
        handlePageChange(data) {
            this.page = data.pageIndex
            this.limit = data.pageSize
            this.getWjxList();
        },
        // 删除提示
        isdelete(id) {
            this.$confirm("确定删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let data = {
                    id
                }
                this.$Api.wjx.deleteQuest(data)
                    .then(res => {
                        // console.log('删除成功:',res);
                        this.getWjxList()
                    })
                    .catch(err => {
                        console.log('删除失败:', err)
                    })
            })
                .catch((err) => {
                    console.log('err:', err)
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        //筛选课程 [包括：筛选课程类型，模糊查询课程]
        filterCourse() {
            this.page = 1;
            this.getWjxList();
        },
        // 获取问卷星问卷列表
        async getWjxList() {
            let data = {
                projectId: this.$store.state.user.currentProject.id, //当前项目id
                page: this.page,
                limit: this.limit,
                queryKey: this.formInline.search
            }
            let resData = await this.$Api.wjx.listTeacher(data);
            let currentTime = Date.now();
            // console.log('当前时间:',currentTime);
            resData.data.records.map(item => {
                if (item.beginTime && item.endTime) {
                    let beginTime = new Date(item.beginTime).getTime();
                    let endTime = new Date(item.endTime).getTime();
                    if (currentTime >= beginTime && currentTime < endTime) {
                        item.timeStatus = 1
                    } else if (currentTime < beginTime) {
                        item.timeStatus = 0
                    } else if (currentTime > endTime) {
                        item.timeStatus = 2
                    }
                } else {
                    item.timeStatus = item.status
                }
            })
            this.tableData = resData.data.records;
            this.total = resData.data.total;
        },
        // 操作
        handleWjx(isQuery, id) {
            let data = {
                isQuery,
                id
            }
            this.$Api.wjx.goAnswer(data)
                .then(res => {
                    console.log('获取答题url:', res);
                    this.$router.push({
                        path: '/salon/wjxIframe',
                        query: {
                            breadNumber: 5,
                            iframeUrl: res.data,
                            breadcrumbName: isQuery == 1 ? '查看问卷' : '问卷详情'
                        }
                    })
                })
                .catch(err => {
                    console.log('获取操作url失败:', err)
                })
        },
    },
    mounted() {
        this.getWjxList();
    }
};
</script>
  
<style lang="less" scoped>
@import "./less/wjxList.less";

.manage_project {
    margin: 0 auto;
    padding: 20px 25px;

    .mid-bot {
        margin-top: 20px;
        .noTime {
            display: inline-block;
            width: 178px;
        }

        .noTime {
            display: inline-block;
            width: 178px;
        }

        .signIn-status {
            margin-left: 35px;
            border: 1px solid #999999;
            border-radius: 8px;
            padding: 0 10px;
            color: #999999;
            font-size: 12px;

            &.signingIn {
                border: 1px solid #508EF9;
                color: #508EF9;
            }
        }
    }
}</style>